<template>
  <div></div>
</template>

<script>
import Storage from "../../../models/storage";

export default {
  name: "LineAuth",
  mounted() {
    this.authorize();
  },
  methods: {
    async authorize() {
      this.$store.state.isloading = true;
      if (this.$route.query.error) {
        await this.$router.push("/login");
      }
      const code = this.$route.query.code;
      let user = this.$store.state.auth.user;
      let userId = null;
      if (user != null) userId = user.id;

      //accountBind(快速連結)
      if (user == null && Storage.get("accountBind") != undefined) {
        userId = Storage.get("accountBind").id;
        Storage.remove("accountBind");
      }

      let par = { AuthCode: code, UserId: userId };

      await this.$store
        .dispatch("auth/lineLogin", par)
        .then(() => {
          if (userId == null) {
            let name = this.$store.state.auth.user.name;
            let message = name === "" ? "登入成功" : `${name}，歡迎回來！`;
            this.notify("success", message);
            this.$router.push("/ChargeMeContactBook/ClassRoomCenter");
          } else {
            this.notify("success", "綁定成功！");
            this.$router.push("/MemberCenter");
          }
        })
        .catch(err => {
          console.log(err);
          if (userId == null) {
            Storage.remove("user");
            this.notify("error", "登入失敗！");
            this.$router.push("/login");
          } else {
            this.notify("error", "綁定失敗！");
            this.$router.push("/MemberCenter");
          }
        })
        .finally(() => {
          this.$store.state.isloading = false;
        });
    },
  },
};
</script>

<style scoped></style>
